.section-video-list {
    grid-column: 1;
    overflow-y: auto;
    min-width: fit-content;

    .element {
        background-color: white;
    }

    .selected {
        background-color: rgba(0,0,0,0.2);
        font-weight: bold;
        pointer-events: none;
    }
    
    p {
        font-size: 1.2em;
    }

    div {
        border-radius: 1em;
        padding: 1em;
        margin-top: 10px;
    }
}