@import "../../../css/variables";

.container-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $background;
    color: $color;
    height: 98.6vh;
    gap: 1em;

    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.container-login form {
    padding: 1em;

    button {
        font: bold 13px Arial;
        border:none;
        border-radius: 10px;
        padding: 2px;
    }

    h1 {
        text-align: center;
    }

    input,button {
        text-align: center;
        padding: 0.5em;
    }

    display: flex;
    flex-direction: column;
    gap: 1em;    
    width: 50%;
}