.section-counter {
      grid-column: 3;
      min-width: fit-content;

      .triangle {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #cecece;
            transition: .5s ease;
        
      }
        
      .triangle-up {
         transform:rotate(180deg);
         transform-origin: 50% 40%;
      }

      .count {
            font-size: 1.3em;
            button {
                  font-size: 0.7em;
            }
            div {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: row;
            }
      }

      .items {
            overflow-y: auto;
            height: 50vh;
      }

      .select-category {
            cursor: 'pointer';
            font-size: '1.3em';
            font-weight: 'bold';
      }
}