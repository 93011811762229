@import "../../../css/variables";
.tuto {
    background-color:$InfoBox;
    padding: 1em;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    border-radius: 1em;
    grid-column: 1/4;

    justify-content: center;

    .element {
        margin-left: 1em;
        margin-right: 1em;
        color: white;
        padding: 1em;
        border-radius: 1em;
        font-weight: bolder;
        display: flex;
        flex-direction: column;

        label {
            padding: 0.1em;
        }

        kbd {
            background-color: $button-background;
            border-radius: 1em;
            // border: 1px solid $button-color;
            box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
            color: #333;
            display: inline-block;
            // font-size: .85em;
            font-weight: bold;
            // line-height: 1;
            padding: 0.5em;
            white-space: nowrap;
            margin-left: 1em;
            margin-right: 1em;
           }

           white-space: nowrap;

    }

    margin: 1em;


}

.glossaire {
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: absolute;
    background-color: rgba(124, 124, 124);
    opacity: 0.9;
    margin: 1em;
    padding: 1em;
    width: 80vw;
    height: fit-content;
    max-height: 70vh;

    .vertical-manager {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 1em;
        overflow-y: scroll;
        height: 60vh;
    }

    button {
        border-radius: 0;
        background-color: darkgray;
        padding: 1em;
    }

    margin-top: 10%;
    margin-left: 20%;
    max-width: 70%;
}
