@import "../../../css/variables";

.dashboard-admin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2;
    grid-row: 3;
    gap: 1em;
    padding: 1em;
    background-color: $background;
    border-radius: 1em;
    font-size: 1.3em;

    h1 { 
        text-align: center;
        font-size: 1.5em;
    }

    h2 { 
        font-size: 1.4em;
    }

    .count-saved {
        margin-left: 0.5em;
        margin-right: 0.5em;
        width: 10%
    }

    .category-label { 
        margin-right: 0.5em;
    }

    button {
        font-size: 0.9em;
    }

    .trash {
        margin-left: 0.5em;
    }
}