@import "../../../css/variables";

.header {
    display: flex;
    flex-direction: row;
    padding: 1em;
    background-color: $pannel-headder;
    color: $pannel-headder-color;
    grid-row: 1;
    justify-content: space-between;
    grid-column: 1/3;
    border-bottom: 1px solid $background;
}

.main-header {
    display: flex;
    flex-direction: row;
    padding: 1em;
    background-color: $background;
    color: $color;
    grid-row: 1;
    justify-content: space-between;
    grid-column: 1/4;
    border-bottom: 1px solid $background;
    height: 15vh;

    button {
        border: none;
    }

    h1 {
        font-size: 3em;
        margin-right: 13%;
    }
}
