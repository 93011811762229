// CLOLOR
$InfoBox : rgba($color: #00000000, $alpha: 0.3);
$InfoText : white;

$background : lightgrey;
$real-background : white;
$color : #251ABF;

$title:  #5E327C;
$title-color : white;

// $color : #5E327C;
// $background : white;

$panel :white;
$panel-color: #251ABF;
// $panel-color: white;


// $pannel-headder:#3B204D;
// $pannel-headder-color:white;

$pannel-headder:white;
$pannel-headder-color:#3B204D;

$panel-input:#686868f1;

$info:#683F84;
$info-color:#fff;

$filler:#fff;
$filler-color:#000080;

// $button-background:#5E327C;
// $button-color:#ffffff;

$button-background:#ffffff;
$button-color:#5E327C;
// $button-hover:#0B3C75;
$button-hover:#251ABF;
$button-color-hover:#fff;

// form

$radius : 1em;
$border : 1px solid $background;
