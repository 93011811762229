$light-grey: #eee;
$gutter-width: 20px;

@import "../../../css/variables";

.tags {
    display: flex;
    flex-direction: column;
    .container {
        width: 100%;
        .progress {
            width: 100%;
        }
        .tagger {
            position: absolute;
        }
    }
    .slider {
        width: 100%;
    }
}

.player-wrapper {
    width: 40vw;
    height: 50vh;
    margin-bottom: 0.5em;
}

.react-player { 
    background: rgba(0, 0, 0, .1);
}

button {
    margin: 3px;
    padding: 6px 12px;
    border: 0;
    border-radius: 3px;
    outline: 0;
    background-color: $light-grey;
  
    &:focus {  background: darken($light-grey, 5); }
    &:hover {  background: darken($light-grey, 10); }
    &:active { background: darken($light-grey, 15); }
}

body {
    font-size: 14px;
}
  
em {
  font-style: italic;
}

body,
h1,
h2,
h3 {
  margin-bottom: 1em;
}

h1 { font-size: 20px; }
h2 { font-size: 16px; margin-top: 1em; }

progress {
  width: 100%;
}

.section {
  border-radius: 1em;
  padding: 1em;
  border: none;
  background-color: $background;
  display: inline-block;
  margin: $gutter-width;
  vertical-align: top;
}

.section-video {
  grid-column: 2;
  max-height: fit-content;
}

.controls {
  display: flex;
  justify-content: center;

  img + img {
    margin-left: 4em;
  }
  
  span {
    margin-left: 0.5em;
    font-size: 1.2em;
  }
  
}

.frame {
  margin-bottom: 1.5em;
  display: flex;
  justify-content: center;

  input {
    margin-left: 0.5em;
  }
}