@import '../../../css/variables';

.page-selector {
    margin-top: 2em;
    grid-column: 1/4;

    .buttonList {
        padding: 0.5em;
        gap: 1em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .selected {
            background-color: $button-hover;
            color: $button-color-hover;
        }

        label {
            font-size: 1.2em;
            font-weight: bold;
        }
    }
}